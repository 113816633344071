
function Loader ({ className, borderTopColor}) {

  // if (!borderTopColor) {
  //   borderTopColor = '#a988e0';
  // }

  return (
    <div>
      <div className="flex justify-center items-center h-full">
      {/* Loader Component */}
      <div className={`loader ${className} ease-linear rounded-full border-4 border-t-4 border-gray-200`}></div>
      {/* Loader styles */}
      <style jsx="true">{`
        .loader {
          animation: spin 1s linear infinite;
        }
        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </div>
  </div>
  );
};

export default Loader;
