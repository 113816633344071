
import { doc, updateDoc } from 'firebase/firestore';
import { db } from './firebase'; // import your Firestore instance
import moment from 'moment';

const updateOnlineStatus = async (docId, newValue) => {
  try {
    // Reference to the specific document in the 'on_demand_access' collection
    const docRef = doc(db, 'on_demand_access', docId);

    // Update only the 'allowedField' field
    await updateDoc(docRef, {
      online_status: newValue,
      online_status_ts: moment().valueOf(),
    });

    return true;
  } catch (error) {
    console.error('Error updating document: ', error);
  }
};


export {
  updateOnlineStatus,
};
