// src/components/PrivateRoute.js
import { useAuth } from '../AuthContext';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const { currentUser, isAuthorized } = useAuth();
  const currentHash = window.location.hash.substring(1); // Remove `#`

  console.log('currentUser', currentUser)

  return currentUser && isAuthorized ? children : <Navigate to={`/login#${currentHash}`} />;
};

export default PrivateRoute;
