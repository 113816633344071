import { BrowserRouter as
  Router,
  Route,
  Routes,
  Link,
  Navigate,
  useLocation
} from 'react-router-dom';

import { AuthProvider } from './AuthContext';
import NurseApp from './components/NurseApp';
import Login from './components/Login';
import PrivateRoute from './components/PrivateRoute';

function App() {
  return (
    <div className="On Demand">
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />

            <Route path="/" element={
              <PrivateRoute>
                <NurseApp/>
              </PrivateRoute>
            } />

          </Routes>
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
