// src/components/Login.js
import { useEffect } from 'react';
import { auth, firebase } from '../utils/firebase'; // Adjust based on your Firebase setup
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const Login = () => {
  const { currentUser, isLoading, isAuthorized } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (currentUser && isAuthorized) {
      console.log('currentUser', currentUser);
      console.log('isAuthorized', isAuthorized);
      console.log('location', location.hash);
      // navigate('/', { state: { hash: location.hash } });
      navigate(`/${location.hash}`);
    }
  }, [currentUser, isAuthorized, navigate]);

  const handleGoogleSignIn = async () => {
      const provider = new firebase.auth.GoogleAuthProvider();

      // force acc selection
      provider.setCustomParameters({
        prompt: 'select_account',
      });

      try {
        await auth.signInWithPopup(provider);
        const currentHash = window.location.hash.substring(1); // Remove `#`
        console.log('currentHash', currentHash);
        // Navigate to the root with the current hash
        navigate(`/#${currentHash}`);
      } catch (error) {
        console.error("Error signing in: ", error);
      }
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-50">
      <div className="bg-white shadow-md rounded-lg p-6">
        <h2 className="text-2xl font-bold mb-6">Sign in to your account</h2>
          <button
            onClick={handleGoogleSignIn}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center justify-center w-full"
          >
            Sign in with Google
          </button>
      </div>
    </div>
  );
};

export default Login;
