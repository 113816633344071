import { Video, MessageSquare, Grid, Info, MoreHorizontal, Home, BarChart2, Folder, Store } from 'lucide-react';
import { ReactComponent as Joyous } from '../assets/joyous.svg';

const Sidebar = () => {
  const sidebarItems = [
    { icon: Home, label: 'Dashboard' },
    // { icon: BarChart2, label: 'Analytics' },
    // { icon: Folder, label: 'History' },
    // { icon: Store, label: 'App Store' },
  ];

  return (
    <div className="w-16 bg-white shadow-md border-r">
      <div className="flex flex-col items-center py-4">
        {/* <div className="w-10 h-10 bg-teal-500 rounded-md mb-6"></div> */}
        <div className="pb-6">
          <Joyous/>
        </div>
        {sidebarItems.map((item, index) => (
          <button key={index} className="mb-4 p-2 hover:bg-gray-200 rounded-md">
            <item.icon className="w-6 h-6 text-gray-600" />
          </button>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
