import { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Video,
  MessageSquare,
  Grid,
  Info,
  MoreHorizontal,
  CircleX,
  LoaderCircle,
} from 'lucide-react';

import Loader from './Loader';
import DropdownButton from './DropdownButton';

const Room = ({ index, room, onClickJoin, onClickLeave, selectedRoom,  onCloseRoom}) => {
  const isEvenRoom = index % 2 === 0;

  const [roomIsSelectedRoom, setRoomIsSelectedRoom] = useState(false);
  const [doctorName, setDoctorName] = useState('');
  const [nurseName, setNurseName] = useState('');
  const [isClosingRoom, setIsClosingRoom] = useState(false);
  const [patientLeft, setPatientLeft] = useState(room.status_of_patient === 'patient_left');
  const [timeInRoom, setTimeInRoom] = useState(0);
  const [timeJoinedOrLeft, setTimeJoinedOrLeft] = useState(null);
  const [statusText, setStatusText] = useState('');
  const [buttonIsLoading, setButtonIsLoading] = useState(false);

  const waitingTime = (epochMs) => {
    return moment(epochMs).fromNow();
  };

  const handleClickJoin = async (room) => {
    setButtonIsLoading(true);
    await onClickJoin(room);
  };

  useEffect(() => {
    setRoomIsSelectedRoom(selectedRoom?.uid === room?.uid);
    setButtonIsLoading(false);
  }, [selectedRoom]);


  useEffect(() => {
    if (roomIsSelectedRoom) {
     const timer = setInterval(() => {
        // setTimeInRoom(timeInRoom + 1);
        setTimeInRoom((prevTime) => prevTime + 1);
      }, 1000);

      return () => clearInterval(timer);
    } else {
      setTimeInRoom(0);
    }

  }, [roomIsSelectedRoom]);

  useEffect(() => {
    if (room.status_of_doctor === 'doctor_joined') {
      setDoctorName(room.doctor_name);
    } else {
      setDoctorName('');
    }

    if (room.status_of_nurse === 'nurse_joined') {
      setNurseName(room.nurse_name);
    } else {
      setNurseName('');
    }

    if (room.status_of_patient === 'patient_left') {
      setPatientLeft(true);
    } else {
      setPatientLeft(false);
    }


    // Initial status text
    if (room.status_of_patient === 'patient_joined') {
      setStatusText(`Joined ${waitingTime(room.created_at)}`);
    } else if (room.status_of_patient === 'patient_left') {
      setStatusText(`Left ${waitingTime(room.patient_left_at)}`);
    }

    // Interval to update status text
    const timer = setInterval(() => {
      if (room.status_of_patient === 'patient_joined') {
        setStatusText(`Joined ${waitingTime(room.created_at)}`);
      } else if (room.status_of_patient === 'patient_left') {
        setStatusText(`Left ${waitingTime(room.patient_left_at)}`);
      }
    }, 60 * 1000);

    return () => clearInterval(timer);

  }, [room]);

  const convertSecondsToTime = (seconds) => {
    // use mm:ss format
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const handleCloseRoom = async () => {
    setIsClosingRoom(true);

    const result = await onCloseRoom(room.uid);

    if (result) {
      setIsClosingRoom(false);
    }
  };

  return (
    <div className={`p-4 ${isClosingRoom && 'bg-gray-300 '} ${isEvenRoom && 'bg-gray-50'} border-b ${roomIsSelectedRoom ? '' : 'hover:bg-gray-100'} cursor-pointer ${roomIsSelectedRoom && 'bg-pink-50'}`}>
      <div className="flex items-center mb-2">
        <div>
          <p className="font-medium ">{room.patient_name}{roomIsSelectedRoom && ' - ' + convertSecondsToTime(timeInRoom)}</p>
          <p className="text-sm text-gray-500">{statusText}</p>


          {doctorName && <p className="text-sm font-bold text-pink-500">Currently With Dr. {doctorName}</p> }
          {nurseName && <p className="text-sm text-blue-500">Currently With Nurse {nurseName}</p> }
        </div>
      </div>
      {roomIsSelectedRoom ? (
        <button
          className="w-full bg-red-500 text-white py-2 rounded-md hover:bg-red-600"
          onClick={() => onClickLeave(room)}
        >
          {buttonIsLoading ?
            <Loader
              className='border-t-red-500 h-6 w-6'
            />
          :
            <div>
              <CircleX className="inline-block mr-2 mb-1" size={16} /> Leave Room
            </div>
          }

        </button>
      ) : (
        <button
          className="w-full bg-pink-500 text-white py-2 rounded-md hover:bg-pink-600"
          onClick={() => handleClickJoin(room)}
        >
          {buttonIsLoading ?
            <Loader
              className='border-t-pink-600 h-6 w-6'
            />
          :
            <div>
              <Video className="inline-block mr-2 mb-1" size={16} /> Join Room
            </div>
          }
        </button>
      )}
      <div className="flex justify-between mt-3">
        <button className="p-2 hover:bg-gray-200 rounded-full">
          <MessageSquare size={20} />
        </button>
        <button className="p-2 hover:bg-gray-200 rounded-full">
          <Grid size={20} />
        </button>
        <button className="p-2 hover:bg-gray-200 rounded-full">
          <Info size={20} />
        </button>
        {/* <button className="p-2 hover:bg-gray-200 rounded-full">
          <MoreHorizontal size={20} />
        </button> */}
        <DropdownButton onCloseRoom={() => handleCloseRoom(room.uid)} />

      </div>
    </div>
  );
};

export default Room;
