
import { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import _ from 'lodash';

import { db } from '../utils/firebase';
import { doc, getDoc, updateDoc, onSnapshot, collection, query, where, orderBy } from 'firebase/firestore';
import { useAuth } from '../AuthContext';
import {
  updateOnlineStatus,
} from '../utils/firebase_api';
import { Video, MessageSquare, Grid, Info, MoreHorizontal, Home, BarChart2, Folder, Store, } from 'lucide-react';
import Sidebar from './Sidebar';
import Room from './Room';
import VideoContent from './VideoContent';
import Toggle from './Toggle';
import { useMaybeRoomContext } from "@livekit/components-react";
import { useLocation } from 'react-router-dom';
import Loader from './Loader';

const NurseApp = () => {
  const { currentUser } = useAuth();
  const location = useLocation();

  const [hideSidebar, setHideSidebar] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [uid, setUid] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [token, setToken] = useState(null);
  const [roomButtonIsLoading, setRoomButtonIsLoading] = useState(false);
  const [onlineStatus, setOnlineStatus] = useState(false);
  const [disableRooms, setDisableRooms] = useState(true);
  const disabledRoomsClass = "bg-gray-300 bg-[repeating-linear-gradient(45deg,_#d1d5db_0px,_#d1d5db_10px,_#e5e7eb_10px,_#e5e7eb_20px)] opacity-50 cursor-not-allowed pointer-events-none";

  function parseFragment(fragment) {
    // Remove the leading '#' if it exists
    const cleanFragment = fragment.startsWith("#") ? fragment.substring(1) : fragment;

    // Split the fragment into key-value pairs
    const params = {};
    cleanFragment.split("&").forEach(pair => {
      const [key, value] = pair.split("=").map(decodeURIComponent); // Decode keys and values
      if (key) {
        params[key] = value !== undefined ? value : null; // Handle keys without values
      }
    });

    return params;
  }

  useEffect(() => {
    console.log('Setting up message listener');
    const handleMessage = async (event) => {
      console.log('Received message:', event);
      if (event.origin !== '*' && event.origin !== 'https://jcc.joyous.team' && event.origin !== 'https://link.joyous.team') {
        console.log('Ignoring message from unauthorized origin:', event.origin);
        return;
      }

      try {
        const data = JSON.parse(event.data);
        console.log('Parsed message data:', data);
        if (data.type === 'checkAlive') {
          console.log('Received checkAlive, sending response');
          event.source?.postMessage(JSON.stringify({
            type: 'tabAliveResponse',
            from: window.location.href
          }), event.origin);
          console.log('Sent tabAliveResponse');
        } else if (data.type === 'updateUrl') {
          console.log('Updating URL to:', data.url);
          // if (token || selectedRoom) {
            // await handleLeaveRoom();
          // }
          const hashValue = data.url.split('#')[1] || '';
          // window.location.href = data.url;
          // window.location.hash = data.url;
          window.location.hash = hashValue;

        }
      } catch (e) {
        console.error('Error handling message:', e);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  useEffect(() => {
    const checkHash = () => {
      console.log('check hash')
      const fragment = window.location.hash.substring(1);

      const fragmentObj = parseFragment(fragment);
      console.log('fragmentObj', fragmentObj)

      if (fragmentObj.hideSidebar) {
        setHideSidebar(true);
      }

      if (fragmentObj.uid) {
        handleJoinRoom({uid: fragmentObj.uid});
      }
    };

    // Check the hash on load
    checkHash();

    // Listen for hash changes
    window.addEventListener('hashchange', checkHash);

    return () => {
      window.removeEventListener('hashchange', checkHash);
    };
  }, []);

  // set status as true on load
  useEffect(() => {
    const setInitialStatus = async () => {
      const uid = currentUser.uid;
      if (uid) {
        await updateOnlineStatus(uid, true);
      }
    };

    setInitialStatus();
  }, []);

  useEffect(() => {
    // Update initial status
    const getCurrentStatus = async () => {
      if (!currentUser?.uid) {
        return;
      }

      try {
        const docRef = doc(db, 'on_demand_access', currentUser.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          // Set status as what is in DB at the current moment
          setOnlineStatus(docSnap.data().online_status);
        }
      } catch (error) {
        console.error('Error getting document:', error);
      }
    };

    // set status as true on load

    getCurrentStatus();

    const updateStatus = async () => {
      console.log('checking online status');

      if (!currentUser?.uid) {
        return;
      }

      try {
        const userDocRef = doc(db, 'on_demand_access', currentUser.uid); // Assumes `userId` is the document ID
        console.log('online status', onlineStatus)

        await updateDoc(userDocRef, {
          online_status: onlineStatus,
          online_status_ts: moment().valueOf(),
        });
      } catch (error) {
        console.error('Error updating online status:', error);
      }
    };

    // Update immediately, then every 10 seconds
    // updateStatus();
    const intervalId = setInterval(updateStatus, 10000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [onlineStatus]);


  useEffect(() => {
    if (!currentUser?.uid) {
      return;
    }

    const uid = currentUser.uid;
    setUid(uid);
    const twoHoursAgoEpochMs = moment().subtract(2, 'hours').valueOf(); // Get epoch time in milliseconds

    const collectionRef = collection(db, 'on_demand_rooms');
    const q = query(collectionRef,
      where('created_at', '>', twoHoursAgoEpochMs),
      // where('status_of_patient', '==', 'patient_joined'),
      // where('status_of_patient', '==', 'patient_left'),
      where('status_of_patient', 'in', ['patient_joined', 'patient_left']),
      orderBy('created_at', 'desc')  // Ordering by created_at in descending order
    );

    const unsubscribeRooms = onSnapshot(q, (querySnapshot) => {
      const results = [];
      const tenMinutesAgoEpoch = moment().subtract(10, 'minutes').valueOf();
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        // if patient left for over 10 minutes, skip this room
        if (data.status_of_patient === 'patient_left' && data.patient_left_at && data.patient_left_at < tenMinutesAgoEpoch) {
          return; // skip this room
        }

        results.push(data);
      });

      const reversedResults = results.reverse(); // make sure the latest patient is last

      setRooms(reversedResults);
    });

    // const unsubscribeOnlineStatus = onSnapshot(doc(db, 'on_demand_access', uid), (doc) => {
    //   if (doc.exists()) {
    //     setOnlineStatus(doc.data().online_status);
    //   }
    // });

    return () => {
      unsubscribeRooms();
      // unsubscribeOnlineStatus();
    };
  }, [currentUser]);

  // Filter out any rooms that have a user left after 10 minutes
  useEffect(() => {

    const interval = setInterval(() => {
      const tenMinutesAgoEpoch = moment().subtract(10, 'minutes').valueOf();
      const filteredRooms = rooms.filter((room) => {
        if (room.status_of_patient === 'patient_left' && room.patient_left_at && room.patient_left_at < tenMinutesAgoEpoch) {
          return false;
        }

        return true;
      });

      console.log('filteredRoooms', filteredRooms)

      setRooms(filteredRooms);
    }, 1000 * 30); // Check every 30 seconds

    return () => clearInterval(interval);
  }, [rooms]);

  useEffect(() => {
    if (onlineStatus) {
      setDisableRooms(false);
    } else {
      setDisableRooms(true);
    }
  }, [onlineStatus]);

  const handleJoinRoom = async (room, closeWindow) => {
    if (!currentUser?.uid) {
      return;
    }

    // if (token || selectedRoom) {
      await handleLeaveRoom(closeWindow);
    // }

    setRoomButtonIsLoading(true);

    try {
      // const response = await axios.post('http://localhost:5001/joyous-web/us-central1/onDemandGenerateTokenForRoom', {
      // const response = await axios.post('https://d06c-73-70-17-241.ngrok-free.app/joyous-web/us-central1/onDemandGenerateTokenForRoom', {
      const response = await axios.post('https://us-central1-joyous-web.cloudfunctions.net/onDemandGenerateTokenForRoom', {
        roomName: room.uid,
        uid: currentUser.uid,
      });
      setToken(response?.data?.token);
      setSelectedRoom(room); // Set the room to join
    } catch (error) {
      console.error('Error generating token for room:', error);
    } finally {
      setRoomButtonIsLoading(false);
    }
  };

  const handleLeaveRoom = async (closeWindow) => {
    console.log('leaving room')
    setToken(null);
    setSelectedRoom(null);
    setRoomButtonIsLoading(false);
    // if (document.pictureInPictureElement) {
    //   document.exitPictureInPicture();
    // }
    if (window.documentPictureInPicture.window && closeWindow) {
      window.documentPictureInPicture.window.close();
      window.joyPip = null;
    }
  };

  const handleCloseRoom = async (roomName) => {
    try {
      // const response = await axios.post('http://localhost:5001/joyous-web/us-central1/onDemandCloseRoom', {
        const response = await axios.post('https://us-central1-joyous-web.cloudfunctions.net/onDemandCloseRoom', {
        roomName,
      });
    } catch (error) {
      console.error('Error generating token for room:', error);
    }

    return true;
  };

  const handleToggleOnStatus = async (value) => {
    try {
      const result = await updateOnlineStatus(uid, value);

      if (result) {
        setOnlineStatus(value);
      }

      return result;
    } catch (error) {
      console.error('Error updating online status:', error);
    }
  }

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      {!hideSidebar && <Sidebar/>}

      {/* Waiting Room */}
      <div className="w-64 bg-white border-r flex flex-col h-full">
        <h2 className="text-lg font-semibold p-4 border-b">Patient Rooms</h2>
        <div className={`flex-grow ${disableRooms && disabledRoomsClass}`}>
          {rooms.length ? (
            _.map(rooms, (room, index) => (
              <Room
                key={room.uid}
                index={index}
                room={room}
                setSelectedRoom ={setSelectedRoom}
                selectedRoom={selectedRoom}
                onClickJoin={() => handleJoinRoom(room)}
                onClickLeave={() => handleLeaveRoom(true)}
                onCloseRoom={() => handleCloseRoom(room.uid)}
              />
            ))
            ) : (
            <p className="text-gray-500 p-4">No patients in the rooms</p>
          )}
        </div>

        <div className="p-4 border-t flex flex-col">
          <Toggle
            toggledOnLabel="Online"
            toggledOffLabel="Offline"
            labelPosition="left"
            onToggleOn={() => handleToggleOnStatus(true)}
            onToggleOff={() => handleToggleOnStatus(false)}
            toggleBgColor="bg-green-500"
            // defaultValue={onlineStatus}
            value={onlineStatus}
          />
        </div>
      </div>


      {/* Main Content / Video Call Area */}
      { selectedRoom ?
        <VideoContent
          selectedRoom={selectedRoom}
          onDisconnect={() => handleLeaveRoom(true)}
          token={token}
        />
        : roomButtonIsLoading && !selectedRoom ?
        <div className="flex-1 p-8 h-full flex flex-col justify-center items-center">
          <Loader
            className='border-t-red-500 h-24 w-24 flex h-full justify-center items-center'
          />
        </div>
        :
        <div className="flex-1 p-8 h-full flex flex-col">
          <div className="h-full flex items-center justify-center text-2xl text-gray-500">
            Welcome {currentUser.displayName}
          </div>
        </div>
      }

    </div>
  );
};

export default NurseApp;
