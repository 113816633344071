// import { initializeApp } from 'firebase/app';
// import { getAuth, GoogleAuthProvider, FacebookAuthProvider, EmailAuthProvider, PhoneAuthProvider } from 'firebase/auth';
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import { getFirestore } from 'firebase/firestore';
import "firebase/firestore";
import 'firebase/functions';
import { getFunctions } from 'firebase/functions';


const firebaseConfig = {
  apiKey: 'AIzaSyDsjHaCGbqNB8qSZ1N_Id1H4mMWWZ7ogX0',
  authDomain: 'joyous-web.firebaseapp.com',
  projectId: 'joyous-web',
  storageBucket: 'joyous-web.appspot.com',
  messagingSenderId: '957666142920',
  appId: '1:957666142920:web:cad07ab983049f0160d3fd',
  measurementId: 'G-HJJ3MVMFVH'
};

// v9 that doesn't work
// const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);

const app = firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
window.auth = auth;

const db = getFirestore(app);
const functions = getFunctions(app);
// const db = firebase.firestore();

// export { app, auth, PhoneAuthProvider };
export {
  auth,
  db,
  functions,
  firebase,
};
