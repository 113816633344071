import { useState, useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash';
import MyVideoConference from './MyVideoConference';
import RoomComponent from './RoomComponent';
import VideoFeed from './VideoFeed';
import { useMaybeRoomContext, useLocalParticipant, useParticipants } from "@livekit/components-react";
import { PictureInPicture2 } from "lucide-react";


import { useAuth } from '../AuthContext';

import {
  ControlBar,
  GridLayout,
  LiveKitRoom,
  // ParticipantTile,
  RoomAudioRenderer,
  useTracks,
} from '@livekit/components-react';

import '@livekit/components-styles';
import { Track } from 'livekit-client';

const SERVER_URL = 'wss://joyous-4qypx3an.livekit.cloud';

const VideoContent = ({selectedRoom, onDisconnect, token}) => {
  const { currentUser } = useAuth();
  // const rooms = useMaybeRoomContext();

  // console.log('rooms', rooms)
  // const localParticipant = useLocalParticipant();
  // const participants = useParticipants();
  // const remoteParticipants = participants.filter(p => !p.isLocal);
  // console.log('after?')


  const handleRoomConnected = (room) => {
    // const rooms = useMaybeRoomContext();
    // console.log('rooms', rooms)
    // console.log(useMaybeRoomContext())
    // console.log('room', room)
    // Access the local participant
    // const localParticipant = room.localParticipant;

    // Get the local audio track publication
    // const audioTrackPublication = localParticipant.getTrack('audio');

    // if (audioTrackPublication && audioTrackPublication.track) {
    //   const audioTrack = audioTrackPublication.track;

    //   // Access the MediaStreamTrack
    //   const mediaStreamTrack = audioTrack.mediaStreamTrack;

    //   // Process the audio track
    //   // processAudio(mediaStreamTrack);
    // }
  };

  return (
    <div className="flex-1 p-8 h-full flex flex-col">
      {token && selectedRoom ? (
        <div className="h-full flex items-center justify-center text-2xl text-gray-500">
          <LiveKitRoom
            video={true}
            audio={true}
            token={token}
            serverUrl={SERVER_URL}
            // Use the default LiveKit theme for nice styles.
            data-lk-theme="default"
            style={{ height: '100vh' }}
            onDisconnected={() => onDisconnect()}
            // onConnected={(r) =>handleRoomConnected(r)}
          >
            {/* Your custom component with basic video conferencing functionality. */}
            <MyVideoConference />

            {/* <VideoFeed/> */}




            {/* The RoomAudioRenderer takes care of room-wide audio for you. */}
            <RoomAudioRenderer />
            {/* Controls for the user to start/stop audio, video, and screen
            share tracks and to leave the room. */}
            <ControlBar/>
            {/* <RoomComponent /> */}
          </LiveKitRoom>
        </div>
      ) : (
        <div className="h-full flex items-center justify-center text-2xl text-gray-500">
          Welcome {currentUser.displayName}
        </div>
      )}
    </div>
  );
};

function processAudio(mediaStreamTrack) {
  const audioContext = new AudioContext();
  const mediaStream = new MediaStream([mediaStreamTrack]);
  const source = audioContext.createMediaStreamSource(mediaStream);

  // Create a ScriptProcessorNode (deprecated but widely supported)
  const processor = audioContext.createScriptProcessor(4096, 1, 1);

  source.connect(processor);
  processor.connect(audioContext.destination);

  const audioChunks = [];

  processor.onaudioprocess = (event) => {
    const inputData = event.inputBuffer.getChannelData(0);
    // Convert Float32Array to Int16Array
    const buffer = new Int16Array(inputData.length);
    for (let i = 0; i < inputData.length; i++) {
      buffer[i] = inputData[i] * 0x7fff;
    }
    audioChunks.push(buffer);
  };

  // Stop processing after a certain time or based on an event
  setTimeout(() => {
    processor.disconnect();
    source.disconnect();
    audioContext.close();
    sendAudioToGoogleAPI(audioChunks);
  }, 5000); // Process for 5 seconds
}

async function sendAudioToGoogleAPI(audioChunks) {
  const apiKey = 'AIzaSyAmYct9Nt4X1cZe4EIXIXueGXmgiUveI1o'; // Be cautious with API keys

  // Concatenate all audio chunks
  const audioBuffer = concatInt16Arrays(audioChunks);

  // Convert the audio buffer to a Base64 string
  const audioContent = btoa(
    String.fromCharCode(...new Uint8Array(audioBuffer.buffer))
  );

  const requestBody = {
    config: {
      encoding: 'LINEAR16',
      sampleRateHertz: 48000, // Adjust according to your AudioContext sample rate
      languageCode: 'en-US',
    },
    audio: {
      content: audioContent,
    },
  };

  try {
    const response = await fetch(
      `https://speech.googleapis.com/v1/speech:recognize?key=${apiKey}`,
      {
        method: 'POST',
        body: JSON.stringify(requestBody),
      }
    );

    const data = await response.json();
    if (data.results && data.results[0]) {
      const transcription = data.results
        .map((result) => result.alternatives[0].transcript)
        .join('\n');
      console.log('Transcription:', transcription);
      // Update your UI with the transcription
    } else {
      console.error('No transcription results:', data);
    }
  } catch (error) {
    console.error('Error during transcription:', error);
  }
}

// Utility function to concatenate Int16 arrays
function concatInt16Arrays(arrays) {
  let totalLength = 0;
  arrays.forEach((arr) => {
    totalLength += arr.length;
  });

  const result = new Int16Array(totalLength);
  let offset = 0;
  arrays.forEach((arr) => {
    result.set(arr, offset);
    offset += arr.length;
  });
  return result;
}






export default VideoContent;
