import React, { useState, useEffect, useRef } from 'react';
import { MoreHorizontal, DoorClosed, X } from 'lucide-react'; // Use icons from lucide-react

const DropdownButton = ({ onCloseRoom}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null); // Reference for the dropdown

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Handle remove patient option
  const handleCloseRoom= () => {
    onCloseRoom();
    setIsOpen(false); // Close the dropdown after selecting
  };

  // Close the dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false); // Close dropdown if the click is outside the dropdown
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      {/* Button to toggle the dropdown */}
      <button
        className="p-2 hover:bg-gray-200 rounded-full"
        onClick={toggleDropdown}
      >
        <MoreHorizontal size={20} />
      </button>

      {/* Dropdown menu */}
      {isOpen && (
        <div className="absolute left-0 mt-2 w-48 bg-white border rounded-md shadow-xl z-10">
          <ul>
            <li>
              <button
                onClick={handleCloseRoom}
                className="flex items-center gap-2 w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 border-t border-gray-300 first:border-t-0"
              >
                <DoorClosed size={24} /> {/* Closed Door icon */}
                Close Room
              </button>
            </li>
            {/* Additional options can go here */}
            {/* <li>
              <button
                className="flex items-center gap-2 w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 border-t border-gray-300"
              >
                Another Option
              </button>
            </li> */}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropdownButton;
