
import {
  ControlBar,
  GridLayout,
  FocusLayout,
  LiveKitRoom,
  ParticipantTile,
  RoomAudioRenderer,
  useTracks,
  useMaybeRoomContext,
  useLocalParticipant,
  useRemoteParticipants,
} from '@livekit/components-react';

import { Mic, MicOff, Video, VideoOff, LogOut } from "lucide-react";
import React, { useEffect, useState, useRef } from 'react';
// import CustomParticipantTile from './CustomParticipantTile';

// import { BackgroundBlur, ProcessorWrapper, BackgroundTransformer, BackgroundProcessor,
//   // BlurVideoFrameProcessor
//   VirtualBackground,
// } from '@livekit/track-processors';

import { BackgroundBlur, ProcessorWrapper, BackgroundTransformer, BackgroundProcessor,
  // BlurVideoFrameProcessor
  VirtualBackground,
  RVMBackgroundProcessor
} from "@eyalfishler/track-processors-rvm";



// import { VirtualBackground } from '../index'; // import VirtualBackground from '../index.js';

import '@livekit/components-styles';
import { Track, VideoQuality } from 'livekit-client';
import { PictureInPicture2 } from "lucide-react";

export const AIVirtualBackground = (imagePath, assetPaths) => {
  return RVMBackgroundProcessor({ imagePath, assetPaths }, 'ai-virtual-background');
};


function MyVideoConference() {
  // navigator.mediaSession.setActionHandler('enterpictureinpicture', openPIP);
  const localParticipant = useLocalParticipant();
  const room = useMaybeRoomContext();
  const [blurProcessor, setBlurProcessor] = useState(null);

  const [videoElement, setVideoElement] = useState(null);
  const [realVideoElementUsed, setRealVideoElementUsed] = useState(false);
  const [requestedPiPOnce, setRequestedPiPOnce] = useState(false);
  const [pipWindowElement, setPipWindowElement] = useState(window.joyPip || null);
  const [piPEnabled, setPiPEnabled] = useState(false);
  const remoteParticipants = useRemoteParticipants();
  const [micMuted, setMicMuted] = useState(false);
  const [videoOff, setVideoOff] = useState(false);

  // `useTracks` returns all camera and screen share tracks. If a user
  // joins without a published camera track, a placeholder track is returned.
  const tracks = useTracks(
    [
      { source: Track.Source.Camera, withPlaceholder: true},
      { source: Track.Source.ScreenShare, withPlaceholder: false },
    ],
    { onlySubscribed: false },
  );

  const tracksToUse = tracks.filter((track) => !track.participant.identity.includes('agent'));

  useEffect(() => {
    let videoTrack;
    // Apply the blur effect on component mount
    const applyBlurEffect = async () => {
    const videoTrackPublication = localParticipant.cameraTrack;

      if (!videoTrackPublication) {
        return;
      }

      videoTrack = videoTrackPublication.track;

      if (!blurProcessor) {
        // const processor = BackgroundBlur(15); // Adjust blur level
        const processor = VirtualBackground('../virtual_background.jpg');

        setBlurProcessor(processor);  // Save processor to state

        videoTrack.setPublishingQuality(VideoQuality.HIGH);

        // Add the processor to the video track
        await videoTrack.setProcessor(processor);
        console.log('videoTrack', videoTrack)
      }

    };

    applyBlurEffect();

  }, [localParticipant, blurProcessor]);

  const requestPip = async () => {

    const pipWindow = await window.documentPictureInPicture.requestWindow({
      width: 300,
      height: 500,
      preferInitialWindowPlacement: true,
    });

    console.log('created pip window', pipWindow)

    let root;

    const { createRoot } = require("react-dom/client");
    root = createRoot(pipWindow.document.body);

    // Render the PiP React App
    root.render(<PiPApp
      videoElement={videoElement}
      onMute={handleMute}
      onCameraToggle={handleCameraToggle}
      onLeave={handleLeave}
      initialMicMuted={micMuted}
      initialCameraOff={videoOff}
    />);


    pipWindow.document.body.append(videoElement);

    pipWindow.handleMessage = (event) => {
      console.log("message received", event);
    };

    pipWindow.addEventListener("message", pipWindow.handleMessage );
    // const handleMessage = (event) => {
    //   // For security, verify the message origin if possible
    //   // if (event.origin !== "http://your-main-app.com") return;
    //   console.log('message received', event.data)

    //   if (event.data?.type === "MICROPHONE_STATUS") {
    //     setIsMuted(event.data.micMuted);
    //     console.log(`Microphone status in PiPApp: ${event.data.isMuted}`);
    //   }
    // };

    // console.log('adding listener')
    // pipWindow.addEventListener("message", handleMessage);
      // return () => window.removeEventListener("message", handleMessage);

    setPipWindowElement(pipWindow);
    window.joyPip = pipWindow;

    // Event handlers
    pipWindow.removeEventListener("pagehide", exitPiP);
    pipWindow.addEventListener("pagehide", exitPiP);


    setPiPEnabled(true);
    setRequestedPiPOnce(true);
  };

  const exitPiP = async (closeWindow) => {

    if (closeWindow) {
      if (pipWindowElement) {
        pipWindowElement.close();
        window.joyPip = null;
      } else {
        if (window.documentPictureInPicture.window) {
          window.documentPictureInPicture.window.close();
        }
        window.joyPip = null;
      }
    }

    // const playerContainer = document.querySelector(".lk-grid-layout"); // If using parent of <video>
    const playerContainer = document.querySelector('[data-lk-local-participant="false"]'); // If using <video>
    // const playerContainer = document.querySelector('div[data-lk-local-participant="false"]:not(:has(video))');
    if (playerContainer) {
      const hasVideoChild = playerContainer.querySelector('video');
      if (hasVideoChild) {
        playerContainer.removeChild(hasVideoChild);
      }

      try {
        console.log('appending back into container')
        if (!videoElement.classList.contains('placeholder')) {
          playerContainer.append(videoElement);
        }
      } catch (error) {
        console.log('error appending back into container', error)
      }
    }
    setPiPEnabled(false);
    setRealVideoElementUsed(false);
  }

  // When there is a remote participant, trigger this
  useEffect(() => {
    const patientParticipant = remoteParticipants.length && remoteParticipants.find((p) => p.identity.includes('patient'));
    if (!patientParticipant) {
      const div = document.createElement("div");
      div.classList.add("placeholder");
      div.textContent = "Patient Not In Room";
      div.style.display = "flex";
      div.style.justifyContent = "center";
      div.style.alignItems = "center";
      div.style.height = "100%";
      div.style.width = "100%";
      div.style.backgroundColor = "#000";
      div.style.color = "#fff";
      div.style.fontSize = "1.5rem";
      div.style.fontWeight = "bold";

      setVideoElement(div);
      setRealVideoElementUsed(false);

      return;
    }

    let cameraPublication = patientParticipant.videoTrackPublications.entries().next().value
    if (cameraPublication) {
      cameraPublication = cameraPublication[1];
    }

    if (cameraPublication?.track && !realVideoElementUsed && window.documentPictureInPicture.window) {
      const video = cameraPublication.track.attachedElements[0];

      if (video && !realVideoElementUsed) {
        setVideoElement(video);
        setRealVideoElementUsed(true);
      }
    }
  }, [remoteParticipants]);

  useEffect(() => {
    if (!videoElement) {
      return;
    }

    if (pipWindowElement && remoteParticipants.length) {
      console.log('appending video element to pip window')
      let root;

      const { createRoot } = require("react-dom/client");
      root = createRoot(pipWindowElement.document.body);

      // Render the PiP React App
      root.render(<PiPApp
        videoElement={videoElement}
        onMute={handleMute}
        onCameraToggle={handleCameraToggle}
        onLeave={handleLeave}
        initialMicMuted={micMuted}
        initialCameraOff={videoOff}
      />);

      const scriptElement = document.createElement('script');

      scriptElement.textContent = `
        window.addEventListener("message", (event) => {
          console.log("message received zzz", event);
          console.log(event.data.type)
          if (event.data?.type === "MICROPHONE_TOGGLE") {
            window.toggleMute();
          } else if (event.data?.type === "CAMERA_TOGGLE") {
            window.toggleCamera();
          }
        });
      `;

      if (!videoElement.classList.contains('placeholder')) {
        pipWindowElement.document.body.append(videoElement);
        pipWindowElement.document.body.appendChild(scriptElement);
      }

      navigator.mediaSession.setActionHandler('enterpictureinpicture', requestPip);
      pipWindowElement.removeEventListener("pagehide", exitPiP);
      pipWindowElement.addEventListener("pagehide", exitPiP);

    } else {
      navigator.mediaSession.setActionHandler('enterpictureinpicture', requestPip);
    }

    // setRealVideoElementUsed(true);

  }, [videoElement, pipWindowElement, remoteParticipants.length])

  const handlePiP = () => {
    if (piPEnabled) {
      exitPiP(true);
    } else if (videoElement) {
      requestPip();
    }
  };

  const handleMute = (isMuted) => {
    console.log(`Muted: ${isMuted}`);

    // Locate the mute button by its attributes
    const muteButton = document.querySelector(
      'button.lk-button[data-lk-source="microphone"]'
    );

    if (muteButton) {
      // Simulate a click on the button
      muteButton.click();
      console.log("Mute button clicked programmatically.");
    } else {
      console.error("Mute button not found in the DOM.");
    }
  };

  const handleCameraToggle = (isCameraOn) => {
    // Locate the mute button by its attributes
    const cameraButton = document.querySelector(
      'button.lk-button[data-lk-source="camera"]'
    );

    if (cameraButton) {
      // Simulate a click on the button
      cameraButton.click();
    } else {
      console.error("Toggle Camera button not found in the DOM.");
    }
  };

  const handleLeave = async () => {
    console.log('handling leave')
    await exitPiP(true);

    const leaveButton = document.querySelector(
      'button.lk-disconnect-button'
    );

    if (leaveButton) {
      // Simulate a click on the button
      leaveButton.click();
    } else {
      console.error("Leave button not found in the DOM.");
    }
  };

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const micButton = document.querySelector(
        'button.lk-button[data-lk-source="microphone"]'
      );

      const cameraButton = document.querySelector(
        'button.lk-button[data-lk-source="camera"]'
      );

      if (cameraButton && !cameraButton._hasClickHandler) {
        const handleClick = () => {
          setVideoOff(prev => !prev);

          if (pipWindowElement || window.joyPip) {
            window.joyPip.postMessage(
              { type: "CAMERA_TOGGLE"},
              "*" // Replace * with the specific PiP window origin for better security
            );
          }
        }

        cameraButton.addEventListener("click", handleClick);
        cameraButton._hasClickHandler = true; // Custom flag to prevent multiple listeners
      }

      if (micButton && !micButton._hasClickHandler) {
        const handleClick = () => {
          setMicMuted(prev => !prev);

          if (pipWindowElement || window.joyPip) {
            window.joyPip.postMessage(
              { type: "MICROPHONE_TOGGLE"},
              "*" // Replace * with the specific PiP window origin for better security
            );
          }
        };

        micButton.addEventListener("click", handleClick);
        micButton._hasClickHandler = true; // Custom flag to prevent multiple listeners
      }
    });

    // Observe changes to the DOM
    observer.observe(document.body, { childList: true, subtree: true });

    // Cleanup
    return () => observer.disconnect();
  }, []);


  return (
    <div>
      {/* Button for picture in picture */}
      <div className="absolute top-2 right-2 z-10">
        <button
           className="bg-gray-800 text-white p-1 rounded-full shadow-md hover:bg-gray-700"
           onClick={() => handlePiP()}
        >
          <PictureInPicture2 size={32} />
        </button>
      </div>

      <GridLayout tracks={tracksToUse} style={{ height: 'calc(100vh - var(--lk-control-bar-height))' }}>
        {/* The GridLayout accepts zero or one child. The child is used
        as a template to render all passed in tracks. */}
        <ParticipantTile/>
        {/* <CustomParticipantTile /> */}
      </GridLayout>
      <video
        className="w-[1px] h-[1px] opacity-0 absolute placeholder video"
        muted
        autoPlay
        playsInline
      />
      <div className="w-[1px] h-[1px] opacity-0 absolute placeholder left room">
        Left Room
      </div>
    </div>
  );
}

const PiPApp = ({ videoElement, onMute, onCameraToggle, onLeave, initialCameraOff, initialMicMuted}) => {
  const videoContainerRef = useRef(null);
  const [isMuted, setIsMuted] = useState(initialMicMuted || false);
  const [isCameraOff, setIsCameraOff] = useState(initialCameraOff || false);

  window.joyPip.toggleMute = () => {
    setIsMuted(prev => !prev);
  };

  window.joyPip.toggleCamera = () => {
    setIsCameraOff(prev => !prev);
  };

  const handleMuteToggle = () => {
    // console.log('toggling mute in pip')
    // if (videoElement) {
    //   setIsMuted(!isMuted);
    //   // Notify parent about mute toggle
    //   if (onMute) {
        onMute(videoElement.muted);
    //   }
    // }
  };

  const handleCameraToggle = () => {
    // if (videoElement) {
    //   setIsCameraOff(!isCameraOff);
    //   // Notify parent about camera toggle
    //   if (onCameraToggle) {
        onCameraToggle(isCameraOff);
      // }
    // }
  };

  const handleLeave = async () => {
    if (onLeave) {
      await onLeave();
    }
  };

  useEffect(() => {
    if (videoElement && videoContainerRef.current) {
      const pipWindowDocument = videoContainerRef.current.ownerDocument;

      // Inject Tailwind CSS styles into the PiP window
      const tailwindLink = pipWindowDocument.createElement("link");
      tailwindLink.rel = "stylesheet";
      tailwindLink.href = "https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css";
      pipWindowDocument.head.appendChild(tailwindLink);

      // Append the video element to the PiP app
      videoContainerRef.current.appendChild(videoElement);

      // Add Tailwind-like CSS styles to the PiP window
      const style = pipWindowDocument.createElement("style");
      style.textContent = `
        /* Tailwind-like reset for body */
        body {
          margin: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: black;
          overflow: hidden;
        }
        /* Tailwind-like styles for video */
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      `;
      pipWindowDocument.head.appendChild(style);

      return () => {
        pipWindowDocument.head.removeChild(tailwindLink);
        document.body.appendChild(videoElement);
      };
    }
  }, [videoElement]);

  return (
    <div
      ref={videoContainerRef}
      className="relative w-[300px] h-[500px] bg-black"
      style={{ height: "500px", width: "300px", position: "relative" }}
    >
      {/* Control Bar */}
      <div
        className="absolute bottom-0 left-0 w-full bg-black bg-opacity-50 flex justify-around items-center py-2 z-10"
        style={{ zIndex: 10 }}
      >
        {/* Mute Button */}
        <button
          onClick={handleMuteToggle}
          className="text-white bg-gray-800 hover:bg-gray-700 rounded-full p-3"
          aria-label={isMuted ? "Unmute" : "Mute"}
        >
          {isMuted ? <MicOff size={20} /> : <Mic size={20} />}
        </button>

        {/* Camera Toggle Button */}
        <button
          onClick={handleCameraToggle}
          className={`text-white rounded-full p-3 ${
              "bg-gray-800 hover:bg-gray-700"
          }`}
          aria-label={isCameraOff ? "Turn Camera On" : "Turn Camera Off"}
        >
          {isCameraOff ? <VideoOff size={20} /> : <Video size={20} />}
        </button>

        {/* Leave Button */}
        <button
          onClick={handleLeave}
          className="text-white bg-red-600 hover:bg-red-500 rounded-full p-3"
          aria-label="Leave"
        >
          <LogOut size={20} />
        </button>
      </div>
    </div>
  );
};

export default MyVideoConference;
