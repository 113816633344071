// AuthContext.jsx
import React, { createContext, useState, useEffect, useContext } from 'react';
import { auth, firebase, db } from './utils/firebase';
import { collection, doc, getDoc, getDocs, addDoc, setDoc } from 'firebase/firestore';
// import { getDetailsByUid } from './utils/utils';
// import { getPatientByUid, updatePatientData } from './utils/firebase_api';
// import { getPatientMeetings } from './utils/firebase_api';
import axios from 'axios';
import moment from 'moment';
// import { getDrFromDxOrDr, maybeNavigateToFollowUp, maybeNavigateToSelectFollowUpType } from './utils/utils';
import { functions } from './utils/firebase';
import { httpsCallable } from 'firebase/functions';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

function getCookie(name) {
  const nameEQ = `${name}=`;
  const cookiesArray = document.cookie.split(';');
  for (let i = 0; i < cookiesArray.length; i++) {
    let cookie = cookiesArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1); // Remove leading spaces
    }
    if (cookie.indexOf(nameEQ) === 0) {
      return cookie.substring(nameEQ.length, cookie.length);
    }
  }
  return null;
};

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

export const AuthProvider = ({ children }) => {

  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);

  // The cookie is written in the redirect from a new meeting with magic id
  // const getCookie = name => {
  //   const value = `; ${document.cookie}`;
  //   const parts = value.split(`; ${name}=`);
  //   if (parts.length === 2) return parts.pop().split(';').shift();
  // };

  useEffect(() => {
    auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(() => {
      // Check for the current auth state
      const unsubscribe = auth.onAuthStateChanged(async (user) => {
        if (user) {
          const hasAccessDocRef = doc(db, 'on_demand_access', user.uid);
          const hasAccessDoc = await getDoc(hasAccessDocRef);

          if (hasAccessDoc.exists() && hasAccessDoc.data().enabled === true) {
            console.log('got into here')
            setCurrentUser(user);
            setIsAuthorized(true);
          }
        } else {
          setIsAuthorized(false);
          setCurrentUser(null);
        }

        setIsLoading(false);
      });

      return () => unsubscribe();
    })
    .catch((error) => {
      console.error("Error setting persistence:", error);
      setIsLoading(false);
    });

    let tkn = getCookie('telejoyToken');
    if (tkn) {
      auth.signInWithCustomToken(tkn);
      // document.cookie = 'token=;';
    };


    // const unsubscribe = auth.onAuthStateChanged(async (user) => {
    //   setCurrentUser(user);  // Store the full user object, including uid
    //   // console.log('uid', user?.uid);

    //   setIsLoading(false);
    // });

    // let tkn = getCookie('token');
    // if (tkn) {
    //   auth.signInWithCustomToken(tkn);
    //   document.cookie = 'token=;';
    // };

    // return unsubscribe; // Clean up the listener on component unmount
  }, []);

  return (
    <AuthContext.Provider value={{
      currentUser,
      isLoading,
      isAuthorized,
    }}>
      {children}
    </AuthContext.Provider>
  );
};
