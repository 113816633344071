import React, { useState } from 'react';

const Toggle = ({ value, onToggleOn, onToggleOff, label, labelPosition = 'right', toggledOnLabel, toggledOffLabel, toggleBgColor='bg-green-500' }) => {
  // const [isOn, setIsOn] = useState(defaultValue || false);
  const [isLoading, setIsLoading] = useState(false);

  const isPromise = (fn) => fn && typeof fn.then === 'function';

  const handleToggle = async () => {
    setIsLoading(true); // Show loading state while the async operation is running
    let success = false;

    try {
      if (!value && onToggleOn) {
        const result = onToggleOn();
        success = isPromise(result) ? await result : result;
      } else if (value && onToggleOff) {
        const result = onToggleOff();
        success = isPromise(result) ? await result : result;
      }

    } catch (error) {
      console.error("Toggle operation failed: ", error);
    }

    setIsLoading(false); // Stop loading state
  };

  const getLabel = () => {
    if (value && toggledOnLabel) {
      return toggledOnLabel;
    } else if (!value && toggledOffLabel) {
      return toggledOffLabel;
    }

    return label;
  };

  const getLabelTextColor = () => {
    if (value) {
      return 'text-green-500';
    }

    return 'text-gray-600';
  }

  const getLabelClass = () => {
    switch (labelPosition) {
      case 'top':
        return 'flex-col-reverse';
      case 'bottom':
        return 'flex-col';
      case 'right':
        return 'flex-row-reverse';
      case 'left':
      default:
        return 'flex-row justify-between w-full';
    }
  };

  return (
    <div className={`inline-flex items-center ${getLabelClass()} space-x-2`}>
      {getLabel() && <span className={`text-lg ${getLabelTextColor()} font-semibold`}>{getLabel()}</span>}
      <label className="relative inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          className="sr-only"
          checked={value}
          onChange={handleToggle}
          disabled={isLoading} // Disable toggle while loading
        />
        <div
          className={`w-10 h-6 rounded-full transition-colors ${
            value ? toggleBgColor : 'bg-gray-200'
          } ${isLoading ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
        ></div>
        <div
          className={`dot w-4 h-4 bg-white rounded-full absolute left-1 top-1 transition-transform ${
            value ? 'translate-x-full' : ''
          } ${isLoading ? 'opacity-50' : ''}`}
        ></div>
      </label>
    </div>
  );
};

export default Toggle;
